<template>
    <el-scrollbar class="home-container">
        <div class="home-content" v-if="!isShowBody">
            <div class="home-content__left">
                <home-notice v-has-permi="['announcement:list']" />
                <home-commonly-used></home-commonly-used>
                <home-applications></home-applications>
            </div>
            <div class="home-content__right">
                <personal-infor @isShowMyCurriculum="isShowMyCurriculum"></personal-infor>
                <home-pending></home-pending>
            </div>
        </div>
        <div class="home-content" v-if="isShowBody">
            <course-schedule ref="courseScheduleWrapper" @closeMyCurriculum="closeMyCurriculum"></course-schedule>
        </div>
        <div class="copyright-wrap">
            河北鑫考科技股份有限公司 版权所有 © 2008-2023 Xinkaoyun.com, All Rights Reserved | 邮编053000 | 电话400-189-0086 |
            邮箱：834701@xinkaojiaoyu.con
        </div>
        <!-- 更新公告 -->
        <update-notice :fromPath="fromPath"></update-notice>
        <!-- 维修通知 -->
        <el-dialog class="WXDialog" :visible.sync="MaintenanceNotice" append-to-body :close-on-click-modal="false">
            <div class="content">

                <div class="dialog-header">
                    <div class="onOff" @click="MaintenanceNotice = false">
                    </div>
                </div>
                <div class="dialog-main">
                    <div class="goToView">
                        <div @click="goToView" v-hasPermi="['repair:repairForMe']">查看全部 ></div>
                    </div>
                    <div class="context">
                        <div class="dialog-card" v-for="item of listData" :key="item.id">
                            <div class="card_one">
                                <div>单号：{{item.repairCode}}</div>
                                <div>
                                    <div v-html="statusText(Number(item.status))"></div>
                                </div>
                            </div>
                            <div class="card_two">
                                <div class="card_two_item">
                                    <div>报修人：</div>
                                    <div>{{item.repairPerson}}</div>
                                </div>
                                <div class="card_two_item">
                                    <div>报修时间：</div>
                                    <div>{{item.createTime}}</div>
                                </div>
                                <div class="card_two_item">
                                    <div>报修门类：</div>
                                    <div>{{item.repairTypeName}}</div>
                                </div>
                            </div>
                            <div class="card_three">
                                <div class="card_three_item">
                                    <div>报修描述：</div>
                                    <div>{{item.repairDesc}}</div>
                                </div>
                            </div>
                            <div class="card_four">
                                <div class="card_four_btn jd" v-if="item.status == 2" @click="openJD(item)">
                                    结单
                                </div>
                                <div class="card_four_btn zd" v-if="item.status == 2" @click="openZD(item)">
                                    转单
                                </div>
                                <div class="card_four_btn jq" v-if="item.status == 2" @click="openGQ(item)">
                                    挂起
                                </div>
                                <div class="card_four_btn zd" v-if="item.status == 1" @click="openPD(item)">
                                    派单
                                </div>
                                <div class="card_four_btn jd" v-if="item.status == 1" @click="openQiang(item)">
                                    抢单
                                </div>
                                <div class="card_four_btn td" v-if="item.status == 1" @click="openTUI(item)">
                                    退单
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </el-dialog>
        <!-- 结单 -->
        <dialog-wrapper :dialogObj="settlementObj" @handleClose="settlementClose">
            <el-form :model="settlementRuleForm" :rules="settlementRules" ref="settlementRuleForm"
                label-position="right" label-width="94px" class="common-form">
                <el-form-item label="上传照片" prop="finishPhoto">
                    <div style="
                            width: 72px;
                            height: 72px;
                            border: 1px solid #e1e3e6;
                            text-align: center;
                            line-height: 80px;
                            border-radius: 8px;
                        ">
                        <el-upload class="avatar-uploader" action="/api/school/schoolRepair/uploadFile"
                            :headers="headers" :show-file-list="false" :on-success="uploadSuccess"
                            accept=".jpg, .jpeg, .bmp, .gif, .png">
                            <img v-if="settlementRuleForm.finishPhoto" :src="settlementRuleForm.finishPhoto"
                                style="width: 72px; height: 72px" />
                            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            <img v-else :src="plusUrl" style="width: 24px; height: 24px" />
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item label="维修说明" prop="finishDesc">
                    <el-select v-model="settlementRuleForm.finishDesc" filterable allow-create default-first-option
                        placeholder="请填写结单说明" class="input-width-medium" clearable>
                        <el-option v-for="item in zdData" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="settlementClose" class="dialog-reset-button">取消</el-button>
                    <el-button @click="settlementSubmit()" type="primary">确定结单</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
        <!-- 派单、转单、重新派单 -->
        <dialog-wrapper :dialogObj="dispatchOrderObj" @handleClose="dispatchOrderClose">
            <el-form :model="dispatchOrderRuleForm" :rules="dispatchOrderRules" ref="dispatchOrderRuleForm"
                label-position="right" label-width="94px" class="common-form">
                <el-form-item label="选择部门" prop="assignorDeptId">
                    <select-tree ref="treeRef" :tree-option="treeOption"
                        :tree-show-data="dispatchOrderRuleForm.assignorDeptName" :rule-form="dispatchOrderRuleForm"
                        @treeClick="treeClick" @clearTree="clearTree" />
                </el-form-item>
                <el-form-item label="报修委派" prop="assignorId">
                    <el-select v-model="dispatchOrderRuleForm.assignorId" filterable clearable placeholder="请选择教师"
                        style="width: 452px;" @change="changeAssignorId">
                        <virtual-list style="max-height: 200px;overflow-y: auto;" :data-key="'id'"
                            :data-sources="teacherList" :data-component="itemComponent" :keeps="20" :extra-props="{
                                label: 'teacherName',
                                value: 'id'
                            }" />
                    </el-select>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="dispatchOrderClose" class="dialog-reset-button">取消</el-button>
                    <el-button @click="dispatchOrderSubmit()" type="primary">确定委派</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
        <!-- 挂起 -->
        <el-dialog title="报修挂起" :visible.sync="MaintenanceNoticeGuaQi" append-to-body :close-on-click-modal="false" @close="guaQiClose">
            <el-form :model="guaQiForm" :rules="guaQiFormRules" ref="guaQiForm" label-position="right"
                label-width="150px" class="common-form">
                <el-form-item label="上传照片" prop="finishPhoto">
                    <div style="
                            width: 72px;
                            height: 72px;
                            border: 1px solid #e1e3e6;
                            text-align: center;
                            line-height: 80px;
                            border-radius: 8px;
                        ">
                        <el-upload class="avatar-uploader" action="/api/school/schoolRepair/uploadFile"
                            :headers="headers" :show-file-list="false" :on-success="uploadSuccessGua"
                            accept=".jpg, .jpeg, .bmp, .gif, .png">
                            <img v-if="guaQiForm.finishPhoto" :src="guaQiForm.finishPhoto"
                                style="width: 72px; height: 72px" />
                            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            <img v-else :src="plusUrl" style="width: 24px; height: 24px" />
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item label="挂起原因" prop="finishDesc">
                    <el-input type="textarea" v-model="guaQiForm.finishDesc" :rows="3" show-word-limit maxlength="200"
                        placeholder="请填写挂起原因" class="input-width-medium"></el-input>
                </el-form-item>
                <el-form-item label="预计下次维修时间" prop="nextRepairTime">
                    <el-date-picker class="input-width-medium" v-model="guaQiForm.nextRepairTime" type="datetime"
                        format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="guaQiClose" class="dialog-reset-button">取消</el-button>
                    <el-button @click="guaQiSubmit()" type="primary">确定挂起</el-button>
                </div>
            </el-form>
        </el-dialog>
        <!-- 退单 -->
        <dialog-wrapper :dialogObj="rejectObj" @handleClose="rejectClose" class="rejectForm">
            <el-form :model="rejectRuleForm" :rules="rejectRules" ref="rejectRuleForm" label-position="top"
                label-width="120px">
                <el-form-item label="请填写退单原因" prop="rejectReason">
                    <el-input type="textarea" v-model="rejectRuleForm.rejectReason" :rows="3" show-word-limit
                        maxlength="200" placeholder="请填写退单原因"></el-input>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="rejectClose" class="dialog-reset-button">取消</el-button>
                    <el-button @click="rejectSubmit()" type="primary">退单</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
        <dialog-wrapper :dialogObj="dialogVieForzObj" @handleClose="handleVieForClose">
            <div style="
                    color: #333;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 10px;
                    box-sizing: border-box;
                ">
                <span>抢单成功后，其他人将不会看到此报修单，请及时处理报修单，如长时间未处理可能会影响您的评级，是否确认接单？</span>
            </div>
            <div style="text-align: right">
                <el-button :disabled="disabledState" @click="vieForCancel" class="dialog-reset-button">取消</el-button>
                <el-button :disabled="disabledState" @click="vieForConfirm" type="primary">确认接单</el-button>
            </div>
        </dialog-wrapper>
    </el-scrollbar>
</template>

<script>
import {mapState} from "vuex";
import HomeNotice from "@/components/scrollWrapper/firstPage/HomeNotice.vue";
import HomeCommonlyUsed from "@/components/scrollWrapper/firstPage/HomeCommonlyUsed.vue";
import HomeApplications from "@/components/scrollWrapper/firstPage/HomeApplications.vue";
import HomeIntroduce from "@/components/scrollWrapper/firstPage/HomeIntroduce.vue";
import PersonalInfor from "@/components/scrollWrapper/firstPage/PersonalInfor.vue";
import HomePending from "@/components/scrollWrapper/firstPage/HomePending.vue";
import {treeFind} from "@/libs/utils";
import CourseSchedule from "./firstPage/MyCurriculum/CourseSchedule.vue";
import UpdateNotice from "@/components/scrollWrapper/firstPage/UpdateNotice.vue";
import { getToken } from "@/libs/auth";
// import browserTool from "browser-tool";

import axios from "axios";
import {DialogWrapper} from "common-local";
import ElOptionNode from './ElOptionNode.vue';
import virtualList from 'vue-virtual-scroll-list';
import selectTree from "./FirstPageCom/selectTree";

export default {
    name: 'FirstPageScrollWrapper',
    props: {
        fromPath: {
            type: String,
        }
    },
    components: {
        HomeNotice,
        HomeCommonlyUsed,
        HomeApplications,
        HomeIntroduce,
        HomePending,
        PersonalInfor,
        CourseSchedule,
        UpdateNotice,
        DialogWrapper,
        selectTree,
        'virtual-list': virtualList,
    },
    computed: {
        ...mapState({
            originRouters: (state) => state.originRouters,
        }),
        plusUrl() {
            return require("@/assets/images/plus2.png");
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    data() {
        return {
            introduceRouteName: "SummaryIntroduce",
            isShowBody: false,
            // 新增功能维修通知弹窗
            listData: [],
            isGetWeiXiuData: false,
            MaintenanceNotice: false,
            //结单
            settlementObj: {
                title: "报修结单",
                dialogVisible: false,
                width: "640px",
            },
            settlementRuleForm: {
                rejectReason: "",
                id: "",
                status: "4",
                finishPhoto: "",
                finishDesc: "",
            },
            settlementRules: {
                finishPhoto: [
                    {
                        // required: true,
                        message: "请上传照片",
                        trigger: ["change", "blur"],
                    },
                ],
                finishDesc: [
                    {
                        // required: true,
                        message: "请填写维修说明",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            zdData: [],  // 字典数据
            // 转单
            dispatchOrderObj: {
                title: "报修转单",
                dialogVisible: false,
                width: "640px",
            },
            //派单、转单、重新派单
            dispatchOrderRuleForm: {
                assignorId: "",
                id: "",
                status: "3",
                assignorName: "",
                assignorDeptId: "",
                assignorDeptName: "",
            },
            dispatchOrderRules: {
                assignorId: [
                    {
                        required: true,
                        message: "请选择委派老师",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            // 下拉树组件配置
            treeOption: {
                placeholder: "请选择部门",
                key: "assignorDeptId",
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                treeData: [], // 展示数据
				width:'452px'
            },
            teacherList: [],
            itemComponent: ElOptionNode,
            assignorDeptId: "", //报修门类所属部门的id
            recursionList:[],
            recursionObj:[],

            // 挂起
            MaintenanceNoticeGuaQi: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 1000*60*60*24; 
                }
            },
            guaQiForm: {
                id: "",
                finishPhoto: "",
                finishDesc: "",
                nextRepairTime:"",
            },
            guaQiFormRules: {
                finishPhoto: [
                    {
                        // required: true,
                        message: "请上传照片",
                        trigger: ["change", "blur"],
                    },
                ],
                finishDesc: [
                    {
                        // required: true,
                        message: "请填写维修说明",
                        trigger: ["change", "blur"],
                    },
                ],
                nextRepairTime: [
                    {
                        required: true,
                        message: "请填写时间",
                        trigger: ["change", "blur"],
                    },
                ],
            },

            //退单
            rejectObj: {
                title: "退单",
                dialogVisible: false,
                width: "640px",
            },
            rejectRules: {
                rejectReason: [
                    {
                        required: true,
                        message: "请填写退单原因",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            rejectRuleForm: {
                rejectReason: "",
                id: "",
                status: "6",
            },

            // 抢单
            dialogVieForzObj: {
                title: "报修抢单",
                dialogVisible: false,
                width: "400px",
                assignorId: "",
                assignorName: "",
                assignorDeptId: "",
                assignorDeptName: "",
                id: "",
                status: "2",
                assignType: "1",
            },

        };
    },
    created () {
        this.$store.state.user.isGoWXdata = false;
    },
    methods: {
        goToView() {
            this.MaintenanceNotice = false;
            this.$router.push({
                path: '/sService/sch/report',
                query: {
                    isWXTZ: true,
                }
            });

        },
        // 结单
        openJD(data){
            this.getPostDictList(); // 获取字典数据
            this.settlementRuleForm.id = data.id;
            this.settlementObj.dialogVisible = true;
        },
        // 转单
        async openZD(data) {
            await this.getRepairType(data);
            // 转单
            this.dispatchOrderObj.title = "报修转单";
            this.dispatchOrderRuleForm.status = "3";
            this.dispatchOrderRuleForm.id = data.id;
            this.dispatchOrderObj.dialogVisible = true;
        },
        // 挂起
        openGQ(data) {
            this.guaQiForm.id = data.id;
            this.MaintenanceNoticeGuaQi =  true;
        },
        // 退单
        openTUI(data) {
            // 退单
            this.rejectRuleForm.id = data.id;
            this.rejectObj.dialogVisible = true;
        },
        hasPermission(name) {
            return this.originRouters.findIndex((e) => e.name === name) > -1;
        },
        isShowMyCurriculum(e) {
            console.log('eeeee',e);
            this.isShowBody = e;
        },
        closeMyCurriculum() {
            // 关闭我的课表
            this.isShowBody = false;
        },
        // 获取维修通知
        /**
         * @Description: 获取维修通知
         * @Author: 周浩
         * @Date: 2024-10-31 10:36:42
        */
        async getMaintenanceNoticeData() {
            let result = await this.getListData();
            if(result){
                this.MaintenanceNotice = true;
            }else{
                this.MaintenanceNotice = false;
            }
           
        },
        getListData() {
            let name = this.$store.state.user.userName;
            return new Promise((resolve, reject) => {
                this._fet("/school/schoolRepair/listRepairForMe", {
                    schoolId: this.$store.state.schoolId,
                    status: "",
                    type: "2",
                }).then((res) => {
                    this.listData = []
                    if (res.data.code === "200") {
                        let data = res.data.data.list;
                        if(data.length > 0){
                            // data = data.filter(item => {
                            //     if (item.status == "1" || item.status == "2") {
                            //         return true;
                            //     } else {
                            //         return false;
                            //     }
                            // })

                            this.listData = data;
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                        
                    } else {
                        this.$message.error(res.data.msg);
                        resolve(false);
                    }
                })
            })
        },
        // 结单
        /**
         * @Description: 操作-结单
         * @DoWhat: 关闭结单弹窗
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 11:04:23
         */
        settlementClose() {
            this.$refs.settlementRuleForm.resetFields();
            this.settlementObj.dialogVisible = false;
        },
        /**
         * @Description: 确定结单-提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 11:04:23
         */
        settlementSubmit() {
            this.$refs.settlementRuleForm.validate((valid) => {
                if (valid) {
                    this._fet(
                        "/school/schoolRepair/rejectOrClose",
                        this.settlementRuleForm,
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("操作成功");
                            this.settlementClose();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                        this.getListData();
                    });
                }
            });
        },
        uploadSuccess(res, file) {
            console.log(res, file, "res,file");
            if (res.code === "200") {
                this.settlementRuleForm.finishPhoto = res.data.url;
            } else if (res && res.msg) {
                this.$message.error(res.msg);
            }
        },
        // 转单
        /**
         * @Description: 取消/关闭派单、转单、重新派单
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 11:04:23
         */
        dispatchOrderClose() {
            this.$refs.dispatchOrderRuleForm.resetFields();
            this.$refs.treeRef.$data.filterText = "";
            this.dispatchOrderRuleForm.assignorName = "";
            this.dispatchOrderRuleForm.assignorDeptId = "";
            this.dispatchOrderRuleForm.assignorDeptName = "";
            this.dispatchOrderObj.dialogVisible = false;
        },
        treeClick(data) {
            console.log(data, "555");
            this.dispatchOrderRuleForm.assignorDeptName = data.name;
            this.dispatchOrderRuleForm.assignorDeptId = data.id;
            this.getTeacherList([data.id]);
            this.$refs.treeRef.$refs.elSelect.blur();
        },
        clearTree() {
            this.dispatchOrderRuleForm.assignorDeptId = "";
            this.dispatchOrderRuleForm.assignorDeptName = "";
            this.getTeacherList(this.recursionList);
        },
        /**
         * @Description: 获取教师列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 11:22:46
         */
        getTeacherList(e) {
            this._fet("/school/schoolTeacher/getTeaListByScheduling", {
                schoolId: this.$store.state.schoolId,
                organIdList: e,
            }).then((res) => {
                if (res.data.code == "200") {
                    setTimeout(() => {
                        res.data.data.forEach((item) => {
                            this.$set(item, "label", item.teacherName);
                            this.$set(item, "value", item.id);
                        });
                        this.teacherList = res.data.data;
                        console.log(this.teacherList, "教师下拉");
                        this.dispatchOrderRuleForm.assignorId = '';

                    }, 200);
                }
            });
        },
        /**
         * @Description: 确认委派
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-13 16:44:29
         */
        dispatchOrderSubmit() {
            this.$refs.dispatchOrderRuleForm.validate((valid) => {
                if (valid) {
                    this.dispatchOrderRuleForm.assignorName =
                        this.teacherList.find(
                            (i) =>
                                i.id === this.dispatchOrderRuleForm.assignorId,
                        ).label;
                    this._fet(
                        "/school/schoolRepair/dispatch",
                        this.dispatchOrderRuleForm,
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("操作成功");
                            this.dispatchOrderClose();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                        this.getListData();
                    });
                }
            });
        },
        /**
         * @Description: 派单、转单、重新派单拿去报修门类的部门id
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-17 18:17:14
         * @param {*} data
         */
         getRepairType(data) {
            this._fet(`/school/repairType/info/` + data.repairTypeId).then(
                (res) => {
                    if (res.data.code === "200") {
                        this.assignorDeptId = res.data.data.deptId;
                        this.getDeptList();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        /**
         * @Description: 转单派单时获取部门列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-13 09:43:04
         */
        getDeptList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.$store.state.schoolId,
                organType: "1",
                parentList: this.assignorDeptId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.treeOption.treeData = this.formatRouterTree(
                        JSON.parse(JSON.stringify(res.data.data.list)),
                        "id",
                        "parentOrg",
                    );
                    this.allOptRecursion(this.treeOption.treeData)
                    this.getTeacherList(this.recursionList);
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        formatRouterTree(data, key, parentKey) {
            let parents = [];
            let children = [];
            data.forEach((item, index) => {
                let find = data.find((i) => item[parentKey] === i[key]);
                if (find && item[parentKey] !== undefined) {
                    children.push(item);
                } else {
                    parents.push(item);
                }
            });
            dataToTree(parents, children, key, parentKey);

            function dataToTree(parents, children, key, parentKey) {
                parents.map((p) => {
                    children.map((c, i) => {
                        if (c[parentKey] === p[key]) {
                            let _c = JSON.parse(JSON.stringify(children));
                            _c.splice(i, 1);
                            dataToTree([c], _c, key, parentKey);
                            if (p.children) {
                                p.children.push(c);
                            } else {
                                p.children = [c];
                            }
                        }
                    });
                });
            }

            return parents;
        },
        allOptRecursion(data) {
            this.recursionList = []
            data.forEach(v => {
                if (v.children) {
                    this.allOptRecursion(v.children)
                }
                this.recursionList.push(v.id)
                this.recursionObj.push({
                    id:v.id,
                    name:v.name
                })
            })
        },
        changeAssignorId(e){
            if(e){
                let target = this.teacherList.find(item => item.id == e);
                console.log('target',target);
                let submitObj = {};
                const deptIdString = target.deptId.split(',');
                for (let i = 0; i < this.recursionObj.length; i++) {
                    for (let j = 0; j < deptIdString.length; j++) {
                        if (this.recursionObj[i].id == deptIdString[j]) {
                            submitObj = this.recursionObj[i];
                            console.log('submitObj',submitObj);
                            break;
                        }
                    }
                }
                if (!this.dispatchOrderRuleForm.assignorDeptId && !this.dispatchOrderRuleForm.assignorDeptName) {
                    this.dispatchOrderRuleForm.assignorDeptName = submitObj.name;
                    this.dispatchOrderRuleForm.assignorDeptId = submitObj.id;
                }
            }else{
                this.dispatchOrderRuleForm.assignorDeptName = '';
                this.dispatchOrderRuleForm.assignorDeptId = '';

            }
        },
        // 挂起
        /**
         * @Description: 操作-挂起
         * @DoWhat: 关闭结单弹窗
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 11:04:23
         */
        guaQiClose() {
            this.$refs.guaQiForm.resetFields();
            this.MaintenanceNoticeGuaQi = false;
        },
        /**
         * @Description: 确定挂起-提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 11:04:23
         */
        guaQiSubmit() {
            this.$refs.guaQiForm.validate((valid) => {
                if (valid) {
                    this._fet(
                        "/school/schoolRepair/suspend",
                        this.guaQiForm,
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("操作成功");
                            this.guaQiClose();
                            this.getListData();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            });
        },
        uploadSuccessGua(res, file) {
            console.log(res, file, "res,file");
            if (res.code === "200") {
                this.guaQiForm.finishPhoto = res.data.url;
            } else if (res && res.msg) {
                this.$message.error(res.msg);
            }
        },
        // 退单
        /**
         * @Description: 操作-退单
         * @DoWhat: 提交退单
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 15:44:00
         */
        rejectSubmit() {
            this.$refs.rejectRuleForm.validate((valid) => {
                if (valid) {
                    this._fet(
                        "/school/schoolRepair/rejectOrClose",
                        this.rejectRuleForm,
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("操作成功");
                            this.rejectClose();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                        this.getListData();
                    });
                }
            });
        },
        /**
         * @Description: 关闭退单弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-10-31 15:44:00
         */
        rejectClose() {
            this.$refs.rejectRuleForm.resetFields();
            this.rejectObj.dialogVisible = false;
        },
        /**
         * @Descripttion: 获取字典数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-11-4 14:29:42
         */
        async getPostDictList() {
            let res = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    dictKey: "finishScript",
                    schoolId: this.$store.state.schoolId,
                },
            );
            if (res.data.code === "200") {
                let data = res.data.data;
                this.zdData = data;
            } else {
                this.$message.error(res.data.msg);
            }

        },
        statusText(num){
            switch (num) {
                case 1:
                    return `<span style='display:inline-block; align-items: cneter; width:8px; height:8px; border-radius: 50%; background-color: #C8CACC; margin-right:5px;;margin-bottom: 2px;'></span><span>待维修</span>`;
                case 2:
                    return `<span style='display:inline-block; align-items: cneter; width:8px; height:8px; border-radius: 50%; background-color: #0C59F2; margin-right:5px;;margin-bottom: 2px;'></span><span>处理中</span>`;
                case 3:
                    return `<span style='display:inline-block; align-items: cneter; width:8px; height:8px; border-radius: 50%; background-color: #E66B2E; margin-right:5px;margin-bottom: 2px;'></span><span>已退单</span>`;
                case 4:
                    return `<span style='display:inline-block; align-items: cneter; width:8px; height:8px; border-radius: 50%; background-color: #04CCAB; margin-right:5px;margin-bottom: 2px;'></span><span>已完成</span>`;
                case 6:
                    return `<span style='display:inline-block; align-items: cneter; width:8px; height:8px; border-radius: 50%; background-color: #F6A526; margin-right:5px;;margin-bottom: 2px;'></span><span>已挂起</span>`;
            }
        },
        /**
         * @Description: 操作-抢单关闭
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-18 14:38:18
         */
        handleVieForClose() {
            this.dialogVieForzObj.dialogVisible = false;
        },
        /**
         * @Description: 操作-抢单
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-18 12:00:13
         */
         openQiang(data) {
            if (
                this.$store.state.teacherId &&
                this.$store.state.teacherId !== ""
            ) {
                this.dialogVieForzObj.assignorId = this.$store.state.teacherId;
                this.dialogVieForzObj.id = data.id;
                this.dialogVieForzObj.assignorName = this.$store.state.userName;
                this.dialogVieForzObj.assignorDeptId = data.assignorDeptId;
                this.dialogVieForzObj.assignorDeptName = data.assignorDeptName;
                this.dialogVieForzObj.dialogVisible = true;
            } else {
                this.$message.warning("系统管理员不允许抢单!");
                return false;
            }
        },
        /**
         * @Description: 操作-抢单确认
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-18 12:00:13
         */
         vieForConfirm() {
            this.disabledState = true;
            this._fet(
                "/school/schoolRepair/dispatch",
                this.dialogVieForzObj,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("操作成功");
                    this.disabledState = false;
                    this.dialogVieForzObj.dialogVisible = false
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                    this.disabledState = false;
                }
                this.getListData();
            });
        },
        vieForCancel(){
            this.dialogVieForzObj.dialogVisible = false
        },
        // 派单
        async openPD(data) {
            await this.getRepairType(data);
            // 派单
            this.dispatchOrderObj.title = "报修派单";
            this.dispatchOrderRuleForm.status = "2";

            this.dispatchOrderRuleForm.id = data.id;
            this.dispatchOrderObj.dialogVisible = true;
        },
    },
    watch: {
        "$store.state.user.isGoWXdata": {
            immediate: false,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue) {
                    this.getMaintenanceNoticeData();
                }
            }
        }
    },
};
</script>

<style scoped lang="scss">
.home-container {
    font-family: Microsoft YaHei;
    height: calc(100% - 76px);
    .home-content {
        display: flex;
        justify-content: space-between;
        width: 1411px;
        margin: 0 auto;

        &__left {
            width: 930px;
        }

        &__right {
            width: 465px;
        }
    }

    .copyright-wrap {
        width: 100%;
        text-align: center;
        line-height: 13px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin: 8px auto 26px;
    }
}

::v-deep .el-scrollbar__wrap {
    height: calc(100% + 17px);
}
.content {
    float: 1;
    // border: 1px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;

    .dialog-header {
        flex-shrink: 0;
        height: 80px;
        line-height: 40px;
        position: relative;
        .onOff{
            position: absolute;
            right: 5px;
            top: 10px;
            width: 30px;
            height: 30px;
            background-color: #FFFFFF;
            background: url(@/assets/images/wxClone.png) no-repeat;
            background-size: 100%;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .dialog-main {
        flex: 1;
        overflow: hidden;
        margin: 30px;
        margin-top: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #D0CCFF;
        padding: 5px 0px;
        display: flex;
        flex-direction: column;
        .goToView{
            margin: 5px 0px;
            flex-shrink: 0;
            height: 20px;
            div {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #3C7FFF;
                    float: right;
                    margin-right: 20px;
                    user-select: none;
                    cursor: pointer;
                }
        }
        .context{
            flex: 1;
            overflow: auto;
            .dialog-card {
                margin: 0px 20px;
                background: #FFF;
                border-radius: 2px;
                padding: 8px 20px;
                border-bottom: 1px dashed #C4C4C4;
                .card_one{
                    display: flex;
                    justify-content: space-between;
                    &>div {
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 16px;
                        color: #2B2C33;
                        line-height: 36px;
                    }
                    &>div:nth-of-type(2) {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                    }
                }
                .card_two{
                    margin: 5px 0px;
                    display: flex;
                    justify-content: space-between;
                    overflow: hidden;
                   .card_two_item {
                    overflow: hidden;
                        flex: 1;
                        display: flex;
                        &>div:nth-of-type(1) {
                            flex-shrink: 0;
                            width: 80px;
                            text-align: right;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #666666;
                        }
                        &>div:nth-of-type(2) {
                            flex: 1;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                            // 超出文本使用...
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        
                        }
                    }
                }
                .card_three{
                    margin: 5px 0px;
                    display: flex;
                    justify-content: space-between;
                    overflow: hidden;
                   .card_three_item {
                    overflow: hidden;
                        flex: 1;
                        display: flex;
                        &>div:nth-of-type(1) {
                            flex-shrink: 0;
                            width: 80px;
                            text-align: right;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #666666;
                        }
                        &>div:nth-of-type(2) {
                            flex: 1;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                            line-height: 24px;
                        }
                    }
                }
                .card_four{
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    .card_four_btn {
                        margin: 5px;
                            width: 68px;
                            height: 28px;
                            background: #0FC4A8;
                            border-radius: 4px;
                            user-select: none;
                            cursor: pointer;
                            line-height: 28px;
                            text-align: center;
                            border: 1px solid transparent;
                        }
                        .jd{
                            color: #FFFFFF;
                        }
                        .zd {
                            background: #0A5AF1;
                            border-radius: 4px;
                            color: #FFFFFF;
                        }
                        .jq{
                            color: #F59A23;
                            background: #FFFFFF;
                            border-radius: 4px;
                            border: 1px solid #F59A23;
                        }
                        .td{
                            background: #FFFFFF;
                            border-radius: 4px;
                            border: 1px solid #F52323;
                            color: #F52323;
                        }
                }
            }
        
            .dialog-card:hover {
                background: #F2F6FF;
            }
        }
        
    }
}

</style>
